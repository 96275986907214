body {
  max-width: 1980px;
  margin-left: auto;
  margin-right: auto;
}

.widget-HeaderCell__value {
  font-size: 12px;
}

a.ant-dropdown-link.ant-dropdown-trigger {
  color: #575757;
}

.react-grid-Row:last-child {
  margin-bottom: 20px;
}

.react-grid-Viewport {
  font-weight: bold;
}
